import Countdown from "./components/Countdown";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <main>
          <Countdown />
        </main>
      </header>
    </div>
  );
}

export default App;
