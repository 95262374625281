import React, { useState, useEffect } from 'react';

const COUNTDOWN_TARGET = new Date('2026-02-09T00:00:00+01:00');

const getTimeLeft = () => {
    const totalTimeLeft = COUNTDOWN_TARGET - new Date();
    const days = Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((totalTimeLeft / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((totalTimeLeft / (1000 * 60)) % 60);
    const seconds = Math.floor((totalTimeLeft / 1000) % 60);

    return { days, hours, minutes, seconds };
};

const Countdown = () => {
    const [timeLeft, setTimeLeft] = useState(() => getTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(getTimeLeft());
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []); // Added empty dependency array to useEffect

    return (
        // <div className='w-full h-screen bg-blue-950 bg-contain bg-center' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/bg-byebyepolice.jpeg)` }}>
        //     <div className='flex flex-col justify-center items-center lg:pt-28'>
        //         <div className='flex justify-center text-7xl bg-white/90 p-8 text-blue-950 rounded-2xl w-2/4'>Maki<span className='ml-4 font-bold'>čaka penzijo</span>
        //         </div>

        //         <div className='flex flex-row gap-4 justify-center text-5xl w-full text-white mt-8'>

        //             <div className='flex flex-col justify-center items-center content-center max-w-[150px] bg-blue-950/90 border-[10px] border-blue-950 px-28 py-12 rounded-lg'>
        //                 <div>
        //                     <span className='font-bold text-7xl'>{timeLeft.days}</span>
        //                 </div>
        //                 <label className='mt-4 pt-3'>dni</label>
        //             </div>

        //             <div className='flex flex-col justify-center items-center w-1/4 content-center max-w-[150px] bg-blue-950/90 border-[10px] border-blue-950 px-28 py-12 rounded-lg'>
        //                 <div>
        //                     <span className='font-bold text-7xl'>{timeLeft.hours}</span>
        //                 </div>
        //                 <label className='mt-4'>ur</label>
        //             </div>

        //             <div className='flex flex-col justify-center items-center w-1/4 content-center max-w-[150px] bg-blue-950/90 border-[10px] border-blue-950 px-28 py-12 rounded-lg'>
        //                 <div>
        //                     <span className='font-bold text-7xl'>{timeLeft.minutes}</span>
        //                 </div>
        //                 <label className='mt-4'>minut</label>
        //             </div>

        //             <div className='flex flex-col justify-center items-center w-1/4 content-center max-w-[150px] bg-blue-950/90 border-[10px] border-blue-950 px-28 py-12 rounded-lg'>
        //                 <div>
        //                     <span className='font-bold text-7xl'>{timeLeft.seconds}</span>
        //                 </div>
        //                 <label className='mt-4'>sekund</label>
        //             </div>

        //         </div>

        //         <img width={300} src={process.env.PUBLIC_URL + '/byebye.png'} alt="Example" className='mt-8 border-2 p-3 border-white rounded-full bg-black/80' />


        //     </div>
        // </div>



<div className='w-full min-h-screen bg-blue-950 bg-cover bg-center flex justify-center items-center' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/bg-byebyepolice.jpeg)` }}>
    <div className='flex flex-col justify-center items-center md:pt-12 pt-2 lg:pt-28 px-4'>
    <div className='flex justify-center text-4xl md:text-7xl bg-white/90 md:p-8 p-6 mt-8 text-blue-950 rounded-2xl w-full '>Maki<span className='ml-2 md:ml-4 font-bold'>čaka penzijo</span>
       </div>

        <div className='flex flex-row md:gap-4 gap-2 justify-center text-4xl w-full text-white mt-8 flex-wrap'>

            <div className='flex flex-col justify-center items-center content-center md:max-w-[120px] max-w-[80px] min-w-[80px] bg-blue-950/90 border-[8px] border-blue-950 px-0 pb-2 md:px-16 md:py-6 rounded-lg mb-4'>
                <div>
                    <span className='font-bold md:text-5xl text-2xl'>{timeLeft.days}</span>
                </div>
                <label className='mt-2 md:text-5xl text-2xl'>dni</label>
            </div>

            <div className='flex flex-col justify-center items-center content-center md:max-w-[120px] max-w-[80px] min-w-[80px] bg-blue-950/90 border-[8px] border-blue-950 px-0 pb-2 md:px-16 md:py-6 rounded-lg mb-4'>
                <div>
                    <span className='font-bold md:text-5xl text-2xl'>{timeLeft.hours}</span>
                </div>
                <label className='mt-2 md:text-5xl text-2xl'>ur</label>
            </div>

            <div className='flex flex-col justify-center items-center content-center md:max-w-[120px] max-w-[80px] min-w-[80px] bg-blue-950/90 border-[8px] border-blue-950 px-0 pb-2 md:px-16 md:py-6 rounded-lg mb-4'>
                <div>
                    <span className='font-bold md:text-5xl text-2xl'>{timeLeft.minutes}</span>
                </div>
                <label className='mt-2 md:text-5xl text-2xl'>min</label>
            </div>

            <div className='flex flex-col justify-center items-center content-center md:max-w-[120px] max-w-[80px] min-w-[80px] bg-blue-950/90 border-[8px] border-blue-950 px-0 pb-2 md:px-16 md:py-6 rounded-lg mb-4'>
                <div>
                    <span className='font-bold md:text-5xl text-2xl'>{timeLeft.seconds}</span>
                </div>
                <label className='mt-2 md:text-5xl text-2xl'>sek</label>
            </div>

        </div>

        <img width={300} src={process.env.PUBLIC_URL + '/byebye.png'} alt="Example" className='mt-8 border-2 p-3 border-white rounded-full bg-black/80 mb-14' />

    </div>
</div>




    );
};

export default Countdown;
